<template>
	<div class="time-picker-container" @click.stop>
		<vue-scroll-picker :options="hoursArr" v-model="hours" @update:model-value="hoursUpdate" />
		<vue-scroll-picker :options="minutesArr" v-model="minutes" @update:model-value="hoursUpdate" />
	</div>
</template>

<script>
import { VueScrollPicker } from 'vue-scroll-picker'

import 'vue-scroll-picker/lib/style.css'

export default {
	name: 'TimePicker',
	inheritAttrs: false,
	components: { VueScrollPicker },
	props: {
		value: { type: String, default: '' },
		minHour: { type: Number, default: 0 },
		currentDay: { type: Boolean, default: false }
	},
	data() {
		return {
			minutesArr: Array.from({ length: 12 }, (value, index) => String(index * 5).padStart(2, '0')),
			hoursArr: Array.from({ length: 24 }, (value, index) => String(index).padStart(2, '0')),
			hours: '',
			minutes: '',
			currentTime: ''
		}
	},
	created() {
		this.hours = this.getDefaultHour()
		this.minutes = this.getDefaultMinute()
		this.hoursArr = this.hoursArr.slice(this.minHour)
		this.currentTime = `${this.hours}:${this.minutes}`
		this.$emit('update', this.currentTime)
	},
	methods: {
		getDefaultHour() {
			if (!this.currentDay) {
				return this.$moment().hours()
			}
			return this.hoursArr[0]
		},
		getDefaultMinute() {
			if (!this.currentDay) {
				let currentMinutes = this.$moment().minutes()
				currentMinutes = Math.round(currentMinutes / 5) * 5
				if (currentMinutes > 55) {
					this.hours += 1
					currentMinutes = 0
				}
				return currentMinutes
			}
			return this.minutesArr[0]
		},
		hoursUpdate() {
			this.currentTime = `${this.hours}:${this.minutes}`
			this.$emit('update', this.currentTime)
		}
	}
}
</script>

<style lang="scss">
.time-picker-container {
	display: flex;
	position: absolute;
	align-items: center;
	z-index: 100;
	top: 95px;
	right: 34px;
	margin-top: 16px;
	width: 143px;
	height: 250px;
	border-radius: 16px;
	border-top-right-radius: 6px;
	background-color: $color-black-secondary;
}
.time-picker-container:before {
	content: '';
	position: absolute;
	z-index: -1;
	left: 0;
	top: 50%;
	width: 100%;
	transform: translateY(-50%);
	height: 36px;
	border-radius: 7px;
	background: rgba($color-black-secondary, 0.5);
}
.time-picker-container .vue-scroll-picker {
	flex: 1;
}
.vue-scroll-picker {
	height: 100%;
}
.vue-scroll-picker-layer-top {
	border: 0;
}
.vue-scroll-picker-layer-bottom {
	border: 0;
}

.vue-scroll-picker-layer-selection {
	opacity: 1;
	background-color: rgba($color-white, 0.05);
	height: 36px;
}

.vue-scroll-picker-layer-top,
.vue-scroll-picker-layer-bottom {
	background: none;
	height: calc(50% - 18px);
}
.vue-scroll-picker-item {
	display: flex;
	align-items: center;
	height: 36px;
	font-size: 22px;
	line-height: 1;
	justify-content: flex-end;
	padding-right: 20px;
	opacity: 0.5;
	color: $color-white;
}

.vue-scroll-picker-item-selected {
	opacity: 1;
}

.vue-scroll-picker + .vue-scroll-picker .vue-scroll-picker-item {
	padding: 0 0 0 20px;
	justify-content: flex-start;
}
</style>
