<template>
	<form @submit.prevent="onSubmit">
		<slot />
	</form>
</template>

<script setup>
import { useForm } from 'vee-validate'
import { object } from 'yup'

const props = defineProps({
	validationSchema: {
		type: Object,
		default: () => null
	}
})
const event = defineEmits(['submit'])
const { handleSubmit, setErrors: addErrors } = useForm({
	validationSchema: props.validationSchema && object(props.validationSchema)
})

function setErrors(errors) {
	addErrors(errors)
}

defineExpose({ setErrors })

const onSubmit = handleSubmit((values) => {
	event('submit', values)
})
</script>
