import imageObserver from '@/helpers/imageObserver'

export default {
	created: (el, { value, modifiers }) => {
		function loadImage() {
			const img = new Image()

			img.onload = () => {
				imageObserver.unobserve(el)
				if (modifiers.background) {
					el.style.backgroundImage = `url(${img.src})`
					return
				}
				if (modifiers.poster) {
					el.poster = img.src
					return
				}
				el.src = img.src
			}

			img.src = value
		}

		el.loadImage = loadImage

		imageObserver.observe(el)
	}
}
