import { mixed, number, ref, string } from 'yup'
import configClass from '@/config.class'

export const nicknameSchema = string()
	.required('Введите имя в приложении')
	.min(4, ({ min }) => `Никнейм должен содержать минимум ${min} символа`)
	.max(14, ({ max }) => `Никнейм должен содержать максимум ${max} символа`)

export const aboutYourselfSchema = string()
	.min(5, ({ min }) => `Поле о себе должно содержать минимум ${min} символов`)
	.max(600, ({ max }) => `Поле о себе должно содержать максимум ${max} символа`)

export const imageSchema = mixed()
	.ext(['jpg', 'jpeg', 'png', 'heic', 'heif'])
	.aspectRatio(3)
	.size(configClass.maxImgSize)

export const emailSchema = string()
	.required('Введите адрес электронной почты')
	.email('Некорректный адрес электронной почты')

export const passwordSchema = string()
	.required('Введите пароль')
	.min(6, ({ min }) => `Пароль не может быть короче ${min} символов`)
	.max(32, ({ max }) => `Пароль не может быть длиннее ${max} символов`)
// .password()

export const passwordConfirmationSchema = string()
	.required('Пароль необходимо повторить')
	.oneOf([ref('password'), null], 'Пароли не совпадают')

export const postMediaSchema = mixed()
	.required()
	.ext(['jpg', 'jpeg', 'png', 'heic', 'heif', 'mp4', 'mov'], ({ defaultMessage }) => ({
		message: defaultMessage,
		description: 'Переделай в один из этих форматов в каком-нибудь приложении.'
	}))
	.aspectRatio(3, ({ defaultMessage }) => ({
		message: defaultMessage
	}))
	.minDimensions([1000, 217], ({ defaultMessage }) => ({
		message: defaultMessage,
		description: 'Выбери другое фото.'
	}))
	.imageSize(configClass.maxImgSize, ({ defaultMessage }) => ({
		message: defaultMessage,
		description: 'Попробуй уменьшить размер в каком-нибудь приложением.'
	}))
	.videoSize(configClass.maxVideoSize, ({ defaultMessage }) => ({
		message: defaultMessage,
		description: 'Попробуй уменьшить размер в каком-нибудь приложением.'
	}))
	.minVideoDuration(3, ({ defaultMessage }) => ({
		message: defaultMessage,
		description: 'Попробуй снять более длинное.'
	}))
	.maxVideoDuration(30, ({ defaultMessage }) => ({
		message: defaultMessage,
		description: 'Сделай покороче каким-нибудь приложением.'
	}))

export const consumersListSchema = string()
	.required('Введите название')
	.max(100, ({ max }) => `Название списка должно содержать максимум ${max} символов`)
	.noOnlySpaces()

export const paidMessagePriceSchema = number()
	.required('Укажите цену от 99 до 10000 рублей')
	.transform((value) => (Number.isNaN(value) ? null : value))
	.default(0)
	.min(99, ({ min }) => `Минимальная цена ${min} рублей`)
	.max(10000, ({ max }) => `Максимальная цена цена ${max} рублей`)

export const paidMessagePaidTextSchema = string().required('Необходимо заполнить поле').noOnlySpaces().noLinksOrEmail()

export const paidMessageAdditionalTextSchema = string().noOnlySpaces().noLinksOrEmail().nullable()

export const paidMessageFilesHelperSchema = string()
	.transform((value) => value.toString())
	.required('Добавьте хотя бы один файл')

export const paidMessageFilesSchema = mixed()
	.ext(['jpg', 'jpeg', 'png', 'heic', 'heif', 'mp4', 'mov', 'avi', 'mpeg', 'wav'], () => ({
		message:
			'Вероятно, ты пытаешься загрузить файл неподходящего формата.  Допустимые форматы для' +
			' фото: JPEG/JPG, PNG, HEIC, HEIF. Допустимые форматы для видео : MP4, MOV, AVI. Для аудио: MP3, WAV.',
		description: 'Переделай в один из этих форматов в каком-нибудь приложении.'
	}))
	.imageSize(configClass.maxImgSize, ({ defaultMessage }) => ({
		message: defaultMessage,
		description: 'Попробуй уменьшить размер в каком-нибудь приложением.'
	}))
	.videoSize(configClass.maxVideoSize, ({ defaultMessage }) => ({
		message: defaultMessage,
		description: 'Попробуй уменьшить размер в каком-нибудь приложением.'
	}))
