<template>
	<div class="form-control">
		<label v-if="label" :for="name" class="form-label">{{ label }}</label>
		<div class="form-field-wrap" :class="{ invalid: errors.length }">
			<textarea
				:id="name"
				ref="textarea"
				v-bind="$attrs"
				v-model="value"
				:maxlength="maxLength"
				class="form-field form-field-textarea"
			/>
			<span class="form-control-count" v-if="showCount">{{ value?.length ?? 0 }}/{{ maxLength }}</span>
		</div>
		<validation-message v-if="errors.length" :messages="errors" />
	</div>
</template>

<script setup>
import { useTemplateRef } from 'vue'
import { useField } from 'vee-validate'
import ValidationMessage from '@/components/forms/ValidationMessage.vue'

const props = defineProps({
	name: { type: String, required: true },
	type: { type: String, default: 'text' },
	label: { type: String, default: '' },
	modelValue: { type: String, default: '' },
	maxLength: { type: Number, default: 600 },
	showCount: { type: Boolean, default: false },
	rules: {
		type: Object,
		default: () => null
	}
})

const { value, errors } = useField(() => props.name, props.rules, {
	syncVModel: true
})

const textarea = useTemplateRef('textarea')
</script>
