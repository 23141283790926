import { createApp } from 'vue'
import { createPinia } from 'pinia'
// import VueYandexMetrika from 'vue-yandex-metrika'
import * as Sentry from '@sentry/vue'
import lazyImage from '@/directives/lazyImage'
import auth from '@/plugins/auth'
import { allowedErrorsMessages, exceptionTransactions401 } from '@/helpers/sentryExceptions'
import Toast from 'vue-toastification'
import moment from 'moment'
import VCalendar from 'v-calendar'
import 'v-calendar/style.css'
import AppConfig from '@/config.class'
// eslint-disable-next-line import/no-named-as-default
import http from './http'
import '@/helpers/validationRules'
// eslint-disable-next-line
import postCreator from '@/plugins/postCreator'
import App from './App.vue'
import './assets/css/style.scss'
import router from './router'
// import config from './config'
import HomeLayout from './layouts/HomeLayout.vue'
import DefaultLayout from './layouts/DefaultLayout.vue'
import BalanceWithMenuLayout from './layouts/BalanceWithMenuLayout.vue'
import EmptyLayout from './layouts/EmptyLayout.vue'
import BalanceOnly from './layouts/BalanceOnly.vue'
import LogoOnly from './layouts/LogoOnly.vue'
import ArrowBackLayout from './layouts/ArrowBackLayout.vue'

const app = createApp(App)

const options = {
	position: 'bottom-center',
	timeout: 2000,
	closeOnClick: true,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: true,
	draggablePercent: 0.6,
	showCloseButtonOnHover: false,
	hideProgressBar: true,
	closeButton: 'button',
	icon: false,
	transition: 'Vue-Toastification__fade',
	maxToasts: 1,
	newestOnTop: true
}

app.use(Toast, options)

app.directive('lazy', lazyImage)
app.component('Empty', EmptyLayout)
app.component('Default', DefaultLayout)
app.component('Home', HomeLayout)
app.component('BalanceWithMenu', BalanceWithMenuLayout)
app.component('BalanceOnly', BalanceOnly)
app.component('LogoOnly', LogoOnly)
app.component('ArrowBack', ArrowBackLayout)

app.config.globalProperties.$config = AppConfig

// app.use(VueYandexMetrika, {
// 	id: AppConfig.yaMetrikaId,
// 	router,
// 	env: process.env.NODE_ENV,
// 	options: {
// 		clickmap: true,
// 		trackLinks: true,
// 		accurateTrackBounce: true,
// 		webvisor: true
// 	}
// })
if (AppConfig.sentryDSN) {
	Sentry.init({
		dsn: AppConfig.sentryDSN,
		logErrors: true,
		integrations: [
			new Sentry.BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router)
			}),
			new Sentry.Replay()
		],
		beforeBreadcrumb(breadcrumb) {
			if (
				breadcrumb.category === 'fetch' &&
				['yandex', 'googletagmanager'].some((substring) => breadcrumb.data.url.includes(substring))
			) {
				return null
			}
			return breadcrumb
		},
		beforeSend(event, hint) {
			const { type, value } = event.exception.values[0]
			const { transaction } = event.tags

			if (type === 'AxiosError' && value === 'Request failed with status code 403') {
				const { message } = hint.originalException.response.data
				const isExceptionError = allowedErrorsMessages.find((rg) => rg.test(message))

				if (isExceptionError) {
					return null
				}

				if (hint.originalException.response.data?.redirect_to) {
					return null
				}
			}

			if (type === 'AxiosError' && value === 'Request failed with status code 401') {
				if (exceptionTransactions401.includes(transaction)) {
					return null
				}
			}

			if (['service-worker.js', 'service_worker_error'].some((substring) => value.includes(substring))) {
				return null
			}

			return event
		},
		ignoreErrors: [
			'Navigation cancelled',
			'NavigationDuplicated',
			'MyMood не подключён',
			'Notification permission denied.',
			'Service workers are not supported.'
		],
		denyUrls: [/extensions\//i, /^chrome:\/\//i, /^chrome-extension:\/\//i],
		// Performance Monitoring
		tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
		// Session Replay
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0
	})
}

const pinia = createPinia()
pinia.use(({ store }) => {
	store.$config = AppConfig
})

app.use(VCalendar, {
	componentPrefix: 'vc'
})
app.config.globalProperties.$moment = moment
app.use(pinia).use(http).use(router).use(auth).use(postCreator).mount('#app')
