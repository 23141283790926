<template>
	<ul class="validation-list" v-if="messages.length">
		<li class="validation-list-item" v-text="messages[0]" />
	</ul>
</template>

<script>
export default {
	name: 'ValidationMessage',
	props: {
		messages: { type: Array, default: () => [] }
	}
}
</script>

<style lang="scss">
.validation-list {
	margin-top: 2px;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	list-style-type: none;
	display: flex;
	flex-direction: column;
	gap: 5px;
	color: $color-magenta-secondary;
}
</style>
