<template>
	<div class="loader">
		<svg-icon name="spinner" :size="size" />
	</div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'

export default {
	name: 'LoaderComponent',
	components: {
		SvgIcon
	},
	props: {
		size: {
			type: String,
			default: '48px'
		}
	}
}
</script>

<style lang="scss" scoped>
.loader {
	text-align: center;
	width: 100%;
	margin: 0 auto;
	grid-area: auto / span 3;
	// color: $color-white;
	& svg {
		-webkit-animation: rotating 2s linear infinite;
		-moz-animation: rotating 2s linear infinite;
		-ms-animation: rotating 2s linear infinite;
		-o-animation: rotating 2s linear infinite;
		animation: rotating 2s linear infinite;
	}
}

@keyframes rotating {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
</style>
