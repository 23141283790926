<template>
	<div :class="buttonClasses">
		<span class="btn-create-content" :style="{ alignItems: plusAligning }">
			<span :class="{ 'btn-create-inner-content': headerButton }">
				<span v-if="buttonType === createButtonTypes.default" class="btn-create-plus">
					<svg-icon v-if="headerButton" size="24" name="plus-rounded" />
					<span v-else>+</span>
				</span>
				<span v-if="buttonType === createButtonTypes.loading" class="btn-create-progress">
					{{ videoStore.progress }}%
				</span>
				<span
					v-if="buttonType === createButtonTypes.ready"
					:class="['btn-create-arrow', { 'i-arrow-left': !headerButton }]"
				>
					<svg-icon v-if="headerButton" size="24" name="arrow-rounded" />
				</span>
			</span>
		</span>
		<div class="blick hide-desktop" />
		<svg class="progress-bar">
			<linearGradient :id="myGradientId" gradientTransform="rotate(120)">
				<stop offset="30%" stop-color="#ff285c" />
				<stop offset="60%" stop-color="#cd1b7e" />
			</linearGradient>
			<circle
				:style="`stroke-dashoffset: ${progressLength}`"
				cx="50%"
				cy="50%"
				:r="headerButton ? '21' : '25.5'"
				:stroke="`url('#${myGradientId}')`"
			/>
		</svg>
	</div>
</template>

<script setup>
import isMobile from 'ismobilejs'
import { createButtonTypes } from '@/constants'
import SvgIcon from '@/components/SvgIcon.vue'
import { useVideoUploadStore } from '@/stores/videoUpload'
import { computed } from 'vue'

const videoStore = useVideoUploadStore()

const props = defineProps({
	headerButton: {
		type: Boolean,
		default: false
	}
})

const progressLength = computed(() => {
	if (!videoStore.progress) {
		return 160
	}
	if (videoStore.progress >= 100) {
		return 0
	}
	return 160 - Math.ceil((160 / 100) * videoStore.progress)
})

const buttonType = computed(() => {
	if (videoStore.progress === 0) {
		return createButtonTypes.default
	}
	if (videoStore.progress === 100) {
		return createButtonTypes.ready
	}
	return createButtonTypes.loading
})

const buttonClasses = computed(() => ['btn-create', `btn-create_${createButtonTypes[buttonType.value]}`])

const myGradientId = computed(() => (props.headerButton ? 'myGradientMobile' : 'myGradient'))
const plusAligning = computed(() =>
	isMobile().apple.device && props.type === createButtonTypes.default ? 'baseline' : 'center'
)
</script>

<style lang="scss" scoped>
.btn-create {
	width: 56px;
	height: 56px;
	margin: 0 20px;
	border-radius: 50%;
	position: relative;
	border: none;
	cursor: pointer;
	background: -webkit-radial-gradient(50% 120%, ellipse cover, #ed326a, #fff 80%, #ed326a 100%);
	background: radial-gradient(circle at 50% 120%, #cd1b7e, #da2579 10%, #ff275b 80%, #ff285c 100%);
	display: flex;
	align-items: center;
	justify-content: center;
	@media (min-width: $screen-desktop) {
		width: 45px;
		height: 45px;
		background: none;
		margin-right: 0;
	}
	& .blick {
		content: '';
		position: absolute;
		top: 9%;
		left: 12%;
		width: 65%;
		height: 53%;
		border-radius: 50%;
		background: radial-gradient(circle at 47% 0px, #ffffff, rgba(255, 255, 255, 0) 57%);
		filter: blur(0px);
		z-index: 200;
		transform: rotate(-23deg);
		filter: blur(2px);
		&::before {
			content: '';
			width: 100%;
			height: 119%;
			border-radius: 50%;
			position: absolute;
			left: 0;
			top: 17%;
			background: radial-gradient(#ff275b, #ff235b);
			filter: blur(2px);
		}
	}
	&_loading {
		background: radial-gradient(circle at 50% 120%, #777680, #7f8088 10%, #7f8088 80%, #999aa4 100%);
		& .blick::before {
			background: radial-gradient(#999aa4, #777680);
		}
		@media (min-width: $screen-desktop) {
			background: none;
		}
		& .btn-create-inner-content {
			@media (min-width: $screen-desktop) {
				background-color: rgba(255, 255, 255, 0.2);
			}
		}
		& .btn-create-content {
			@media (min-width: $screen-desktop) {
				border-color: rgba(255, 255, 255, 0.2);
			}
		}
	}
	& .progress-bar {
		visibility: hidden;
		position: absolute;
		transform: translateX(-50%);
		left: 50%;
		top: 0;
		stroke: #ff275b;
		stroke-width: 5;
		z-index: 100;
		width: 100%;
		height: 100%;
		@media (min-width: $screen-desktop) {
			stroke-width: 2;
			& circle {
				stroke-width: 2;
			}
		}
	}
	&_loading .progress-bar {
		visibility: visible;
	}
	&:hover:not(.btn-create_loading) {
		& .btn-create-inner-content {
			background-color: #ff4c6b;
		}
		& .btn-create-content {
			border-color: #ff4c6b;
		}
	}
	&:active:not(.btn-create_loading) {
		& .btn-create-inner-content {
			background-color: #ee1367;
		}
		& .btn-create-content {
			border-color: #ee1367;
		}
	}
}

.btn-create-inner-content {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border-radius: 37px;
	background-color: #fe275b;
	width: 37px;
	height: 37px;
	color: #2c2d37;
	transition: background-color 0.3s ease-in-out;
}

.btn-create-content {
	z-index: 300;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	left: 0;
	top: -8px;
	right: 0;
	bottom: 0;
	color: #fff;
	transition: border-color 0.3s ease-in-out;
	@media (min-width: $screen-desktop) {
		position: relative;
		border-radius: 37px;
		border: 2px solid #fe275b;
		min-width: 45px;
		height: 45px;
		color: #2c2d37;
		top: 0;
		z-index: 1;
	}
	&:hover {
		border-color: #fe275b;
	}
}
.btn-create-plus {
	font-size: 44px;
	display: flex;
	@media (min-width: $screen-desktop) {
		position: relative;
		top: -1px;
	}
}
.btn-create-progress {
	font-weight: 600;
	font-size: 12px;
	color: $color-white;
	position: relative;
	top: 2px;
	@media (min-width: $screen-desktop) {
		top: 0;
	}
}
.btn-create-arrow {
	transform: rotate(180deg);
	font-size: 24px;
	width: 100%;
	position: relative;
	top: 4px;
	@media (min-width: $screen-desktop) {
		transform: none;
		color: $color-black-secondary;
		top: -1px;
		right: -3px;
	}
	&:before {
		transform: rotate(180deg) translateX(-1px);
		display: block;
	}
}
circle {
	fill: transparent;
	transform-origin: center;
	transform: rotate(-90deg);
	stroke-width: 5px;
	stroke-dasharray: 160;
	stroke-dashoffset: 160;
	animation: clock-animation 10s linear infinite;
}

.btn-create_loading {
	cursor: default;
}

.create-button-wrap {
	width: 100%;
}
</style>
