import { createVNode, render } from 'vue'
import PostMediaInput from '@/components/PostMediaInput.vue'

export default {
	install: (app) => {
		const vnode = createVNode(PostMediaInput)
		render(vnode, document.body)

		const input = document.querySelector('input#post')
		app.directive('create-post', {
			beforeMount(el) {
				el.addEventListener('click', () => {
					input.click()
				})
			}
		})
	}
}
