import { defineStore } from 'pinia'
import messengerApi from '@/http/messenger'

export const useGuestMessengerStore = defineStore('guestMessenger', {
	state: () => ({
		messagesList: [],
		currentGirl: null
	}),
	getters: {},
	actions: {
		getChatLocalStorage() {
			return localStorage.getItem('guest-chat') ? JSON.parse(localStorage.getItem('guest-chat')) : []
		},
		newGuestMessage(girlId, msg, welcomeMessage = false) {
			const arr = this.getChatLocalStorage()
			const found = arr?.messages?.some((el) => el.girlId === girlId)
			if (!found) {
				if (!arr.messages) {
					const item = {
						messages: []
					}
					Object.assign(arr, item)
				}
				arr.messages.push({
					girlId,
					list: [
						{
							body: msg,
							created_at: Date.now(),
							uid: 0
						}
					]
				})
			} else {
				arr.messages
					.find((el) => el.girlId === girlId)
					.list.push({
						body: msg,
						created_at: Date.now(),
						uid: girlId
					})
			}
			localStorage.setItem('guest-chat', JSON.stringify(arr))
			this.messagesList.push({
				id: Date.now(),
				temporary_id: Date.now().toString(),
				owner_id: welcomeMessage ? '0' : girlId,
				body: msg,
				type_verbose: 'MESSAGE',
				created_at: Date.now()
			})
		},
		checkWelcomeMessage(girlId) {
			const arr = this.getChatLocalStorage()
			const found = arr?.messages?.some((el) => el.girlId === girlId)
			if (!found) {
				return true
			}
			return false
		},
		async getWelcomeMessage() {
			const result = await messengerApi.getGuestWelcomeMessage(this.$config.is_local)
			return result
		},
		getGuestMessages(girlId) {
			const arr = this.getChatLocalStorage()
			const messages = arr.messages.find((el) => el.girlId === girlId)?.list
			messages.forEach((item) => {
				this.messagesList.push({
					id: item.created_at,
					temporary_id: item.created_at,
					owner_id: item.uid,
					body: item.body,
					type_verbose: 'MESSAGE',
					created_at: item.created_at
				})
			})
		},
		resetGuestMessages() {
			this.messagesList = []
		},
		getCurrentGirl() {
			const arr = this.getChatLocalStorage()
			this.currentGirl = arr.girl
		},
		setCurrentGirl(girlId, avatar) {
			const arr = localStorage.getItem('guest-chat') ? JSON.parse(localStorage.getItem('guest-chat')) : { girl: [] }
			arr.girl = {
				girlId,
				avatar
			}
			localStorage.setItem('guest-chat', JSON.stringify(arr))
			this.currentGirl = arr.girl
		}
	}
})
