<template>
	<div class="avatar-circle placeholder-bg">
		<img v-lazy="imagePath" :src="imagePath" alt="Аватар" />
	</div>
</template>

<script>
export default {
	name: 'AvatarCircle',
	props: {
		imagePath: {
			type: String,
			default: ''
		}
	}
}
</script>

<style lang="scss">
.avatar-circle {
	border-radius: 100%;
	background-size: 100%;
	overflow: hidden;
	width: 40px;
	height: 40px;
	min-width: 40px;
	min-height: 40px;

	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
</style>
