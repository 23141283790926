import { defineStore } from 'pinia'
import { usePostStore } from '@/stores/post'
import { useGirlStore } from '@/stores/girl'
import posts from './helper'

export const useHomeStore = defineStore('home', {
	state: () => ({
		posts: null,
		isLoading: false,
		filter: {
			currentGirl: null
		},
		postStore: usePostStore(),
		girlStore: useGirlStore(),
		controller: null
	}),
	getters: {
		postsHasLoaded: (state) => state.posts !== null
	},
	actions: {
		updateLikes(post) {
			posts.findPostAndUpdateLikesValue(this.posts, post)
		},
		async getPosts() {
			this.isLoading = true
			this.posts = null
			this.filter.currentGirl = null
			this.controller = new AbortController()
			this.posts = await this.postStore.fetchPosts(null, this.controller)
			this.isLoading = false
			this.controller = null
		},
		async getMorePosts() {
			await this.postStore.getMorePosts(this.posts)
		},
		applyBlurToPostsOnlyForFans(girlId) {
			if (this.posts) {
				posts.mediaContentVisibleOnlyForFans(this.posts.data, girlId)
			}
		},
		removeBlurFromPosts(girlId) {
			if (this.posts) {
				posts.medialContentVisibleForAllConsumers(this.posts.data, girlId)
			}
		},
		turnOnWaitingSubscription(girlId) {
			if (this.posts) {
				posts.turnOnWaitingSubscription(this.posts.data, girlId)
			}
		},
		async setFilter(girlId) {
			this.posts = null
			this.isLoading = true
			this.controller?.abort()
			this.controller = null
			if (this.filter.currentGirl === girlId) {
				await this.getPosts()
			} else {
				this.filter.currentGirl = girlId
				this.posts = await this.postStore.fetchPosts(girlId)
			}
			this.isLoading = false
		},
		async resetFilter() {
			this.filter.currentGirl = null
			await this.getPosts()
		},
		resetPosts() {
			this.posts = null
		},
		removePostFromList(postId) {
			posts.removePostFromList(this.posts, postId)
		},
		setLastCommentForPost(post) {
			if (this.posts) {
				const lastCommentPost = this.posts.data.find((el) => el.id === post.post.id)
				if (lastCommentPost) {
					lastCommentPost.comment = {
						consumer_avatar: post?.comment?.author?.avatar,
						consumer_id: post?.comment?.author?.id,
						consumer_nickname: post?.comment?.author?.nickname,
						created_at: post?.comment?.created_at,
						text: post?.comment?.text
					}
				}
			}
		}
	}
})
