<template>
	<div class="content p-0">
		<div :class="['header hide-desktop flex row space container', { 'fixed-header': $route.meta?.fixedHeader }]">
			<button
				v-if="!$route.meta?.hideBackBtn"
				type="button"
				@click="$route.meta?.backRouteName ? $router.push({ name: $route.meta?.backRouteName }) : $router.back()"
				class="back"
			/>
			<div v-if="title" :class="['title', { 'left-align-mobile': $route.meta?.titleLeft }]">{{ title }}</div>
			<router-link v-if="$route.meta?.closeBtn" class="close" :to="getAuthorProfile()" />
		</div>
		<slot />
	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useGirlProfileStore } from '@/stores/girlProfile'

export default {
	name: 'ArrowBackLayout',
	computed: {
		...mapState(useGirlProfileStore, {
			girlId: 'id',
			girlNickname: 'nickname',
			verification_status: 'verification_status'
		}),
		title() {
			return this.$route.meta.title
		}
	},
	methods: {
		getAuthorProfile() {
			if (!this.girlNickname || this.verification_status === 'needs') {
				return { name: 'author-profile-id', params: { id: this.girlId } }
			}
			return { name: 'author-profile', params: { nickname: this.girlNickname } }
		}
	}
}
</script>
