<template>
	<dialog-component class="verification-dialog" @close="$emit('close')">
		<template #header-title>Верификация. {{ modalTitle }}.</template>
		<photo-verification
			key="gesture"
			id="gesture"
			v-if="currentStep === 1"
			@submit="currentStep += 1"
			@input="lastVerification.gesture.comment = ''"
			v-model="formData.gesture"
			submit-btn-text="Следующий шаг"
			:image-example="lastVerification.gesture.url || verificationInGestureImage"
			description="Поверни телефон на бок и сделай селфи с жестом, как показано на картинке."
			:comment="gestureComment"
		>
			<div v-if="showGesture" class="gesture">{{ gesture }}</div>
		</photo-verification>
		<photo-verification
			key="photoWithPassport"
			id="photoWithPassport"
			v-if="currentStep === 2"
			@submit="onSubmit"
			@input="lastVerification.passport.comment = ''"
			v-model="formData.passport"
			:is-sending="isSending"
			:image-example="lastVerification.passport.url || verificationPassportImage"
			description="Поверни телефон на бок и сделай селфи с паспортом в развернутом виде так, чтобы дата рождения и фото были отчетливо видны рядом с лицом."
			:comment="passportComment"
		/>
		<div v-if="currentStep === 3">
			<p class="verification-description">
				Нужно немного подождать, пока мы проверим данные. Обычно процесс занимает не более 8 часов. После этого тебе
				станет доступен просмотр чужих постов, а твои собственные посты опубликуются автоматически.
			</p>
			<button-component @click="$emit('close')">Отлично</button-component>
		</div>
	</dialog-component>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useGirlProfileStore } from '@/stores/girlProfile'
import verificationApi from '@/http/girl/verification'
import ModalState from '@/helpers/modalState'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import PhotoVerification from '@/components/forms/PhotoVerification.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import { useHomeStore } from '@/stores/home'
import verificationInGesture from '@/assets/images/verification-in-gesture.jpg'
import verificationPassport from '@/assets/images/verification-passport.jpg'

const modalState = new ModalState()

export default {
	name: 'GirlVerificationDialog',
	components: {
		DialogComponent,
		PhotoVerification,
		ButtonComponent
	},
	data() {
		return {
			isSending: false,
			currentStep: 1,
			gesture: null,
			lastVerification: {
				status: {},
				gesture: {},
				passport: {}
			},
			formData: {
				gesture: null,
				passport: null
			}
		}
	},
	computed: {
		verificationInGestureImage() {
			return verificationInGesture
		},
		verificationPassportImage() {
			return verificationPassport
		},
		passportComment() {
			return this.lastVerification.passport.comment ? `От модератора: ${this.lastVerification.passport.comment}` : ''
		},
		gestureComment() {
			return this.lastVerification.gesture.comment ? `От модератора: ${this.lastVerification.gesture.comment}` : ''
		},
		modalTitle() {
			if (this.currentStep < 3) {
				return `Шаг ${this.currentStep} из 2`
			}
			return 'Почти готово'
		},
		showGesture() {
			return this.formData.gesture === null
		},
		...mapState(useGirlProfileStore, ['id']),
		...mapWritableState(useGirlProfileStore, ['verification_status'])
	},
	async mounted() {
		await this.getLastVerification()
		modalState.openModal(this.$el)
	},
	beforeUnmount() {
		modalState.closeModal(this.$el)
	},
	methods: {
		...mapActions(useHomeStore, ['getPosts', 'resetPosts']),
		async getLastVerification() {
			try {
				const result = await verificationApi.verificationsLast(this.id)
				Object.assign(this.lastVerification, result.data)
				await this.createGesture()
			} catch (e) {
				// eslint-disable-next-line
				console.trace(e)
			}
		},
		async createGesture() {
			const result = await verificationApi.createGesture(this.id)
			if (result) {
				this.gesture = result.gesture
				this.formData.gesture_uuid = result.uuid
			}
		},
		async onSubmit() {
			this.isSending = true
			const formData = new FormData()
			formData.append('gesture', this.formData.gesture)
			formData.append('passport', this.formData.passport)
			formData.append('gesture_uuid', this.formData.gesture_uuid)
			formData.append('_method', 'PUT')
			const result = await verificationApi.createVerificationAttempt(this.id, formData)
			if (result) {
				this.currentStep = 3
				this.verification_status = 'in_process'
				this.resetPosts()
				if (this.$route.name === 'home') {
					await this.getPosts()
				}
			}
			this.isSending = false
		}
	}
}
</script>

<style lang="scss">
.verification-dialog {
	color: $color-gray;

	.dialog-body {
		max-height: calc(100svh - 144px);
		overflow: scroll;
		height: 100%;
	}
}

.verification-dialog .photo-verification-preview {
	margin-left: -20px;
	width: calc(100% + 40px);
}

.gesture {
	position: absolute;
	font-size: 60px;
	top: 50%;
	left: 15%;
	transform: translateY(-50%);
}

.verification-description {
	margin-bottom: 68px;
}
</style>
<style lang="scss" scoped>
:deep(.photo-verification-preview) {
	img {
		width: auto;
		max-height: calc(100vh - 400px);
		max-width: 100vw;
	}
}
</style>
