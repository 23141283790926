import { uploadPaidMedia, deleteMedia } from '@/http/messenger/uploadPaidContent'
import { createPaidMessage } from '@/http/messenger/createPaidMessage'
import { setCoverForPaidMessage } from '@/http/messenger/setCoverForPaidMessage'
import { updatePaidMessage } from '@/http/messenger/updatePaidMessage'
import { publishPaidMessage } from '@/http/messenger/publishPaidMessage'
import { deletePaidMessage } from '@/http/messenger/deletePaidMessage'
import { getUnreadThreadsCount } from '@/http/messenger/getUnreadThreadsCount'
import { getThread } from '@/http/messenger/getThread'
import { markRead } from './markRead'
import { getThreadsList } from './privatesList'
import { search } from './search'
import { locateThread } from './locate'
import { initializeThread } from './privates'
import { getMessages, getMessage } from './getMessages'
import { sendTextMessage, sendImageMessage, sendVideoMessage } from './sendMessage'
import { editMessage } from './editMessage'
import { getReactions, setReaction, removeReaction } from './reaction'
import { getRecommendedListConsumer } from './recommendedListConsumer'
import { getRecommendedListGirl } from './recommendedListGirl'
import { getGuestWelcomeMessage } from './getGuestMessage'

export default {
	getMessages,
	getMessage,
	getThreadsList,
	getRecommendedListConsumer,
	getRecommendedListGirl,
	search,
	locateThread,
	initializeThread,
	sendTextMessage,
	sendImageMessage,
	sendVideoMessage,
	editMessage,
	getReactions,
	setReaction,
	removeReaction,
	markRead,
	uploadPaidMedia,
	createPaidMessage,
	updatePaidMessage,
	setCoverForPaidMessage,
	publishPaidMessage,
	deleteMedia,
	deletePaidMessage,
	getUnreadThreadsCount,
	getThread,
	getGuestWelcomeMessage
}
