<template>
	<div class="comment-field-wrap">
		<div v-if="validationError" class="comment-validation">
			<p>
				{{ validationError }}
			</p>
			<div class="close" @click="validationError = null" />
		</div>
		<div class="comment-input-wrap">
			<div class="user-avatar placeholder-bg" :style="{ 'background-image': `url('${avatar}')` }" />
			<textarea
				rows="1"
				ref="textarea"
				v-model="comment"
				class="form-field"
				id="comment-input"
				placeholder="Введите комментарий"
				@keyup.passive="saveCaretPosition($event)"
				@click.passive="saveCaretPosition($event)"
				@keydown.enter.exact.prevent="onSubmit"
				maxlength="330"
			/>
			<button
				type="button"
				class="send"
				@click.prevent="onSubmit"
				:class="{ active: comment }"
				:disabled="onSending || commentIsEmpty"
			/>
		</div>
	</div>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import { usePostStore } from '@/stores/post'
import { useProfileStore } from '@/stores/profile'

export default {
	name: 'CommentField',
	data() {
		return {
			comment: '',
			caretPosition: 0,
			validationError: null,
			initialInputHeight: null,
			onSending: false
		}
	},
	computed: {
		...mapState(usePostStore, ['id', 'isReady']),
		...mapState(useProfileStore, ['avatar']),
		commentIsEmpty() {
			return this.comment.length <= 0
		},
		isGuest() {
			return this.$auth.check('guest')
		}
	},
	methods: {
		...mapActions(usePostStore, ['sendComment']),
		saveCaretPosition(event) {
			this.caretPosition = event.target.selectionStart
		},
		// refactor
		// eslint-disable-next-line vue/no-unused-properties
		pasteSubstring(substring) {
			const newString = this.comment.split('')
			newString.splice(this.caretPosition, 0, substring)
			this.comment = newString.join('')
		},
		resetComment() {
			this.comment = ''
			this.$nextTick(() => {
				this.$refs.textarea.style.height = this.initialInputHeight
			})
		},
		async onSubmit() {
			if (this.isGuest) {
				this.$router.push({ name: 'login' }).catch(() => {})
			} else {
				try {
					this.onSending = true
					await this.sendComment(this.id, this.comment)
					if (this.isReady) {
						this.$emit('comment-created')
						this.resetComment()
						this.onSending = false
					}
				} catch (e) {
					this.validationError = e.response.data.message
				}
			}
		}
	},
	watch: {
		comment() {
			if (this.initialInputHeight === null) {
				this.initialInputHeight = this.$refs.textarea.style.height
			}
			this.$refs.textarea.style.height = '0px'
			this.caretPosition = this.$refs.textarea.selectionStart
			const scrollHeight = this.$refs.textarea.scrollHeight + 2
			this.$refs.textarea.style.height = `${scrollHeight}px`
		}
	}
}
</script>

<style scoped lang="scss">
.comment-field-wrap {
	width: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 99;
}

.comment-input-wrap {
	width: 100%;
	max-width: 500px;
	padding: 10px 20px;
	background: $color-black;
	position: absolute;
	margin: 0 auto;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;

	.user-avatar {
		display: none;
	}

	.form-field {
		background: $color-black-secondary;
		border: 1px solid $color-black-additional-third;
		border-radius: 12px;
		resize: none;
		font-size: 16px;
	}

	@media (min-width: $screen-desktop) {
		padding: 10px 25px 23px 0;
		background-color: $color-black;
		.user-avatar {
			display: block;
			width: 40px;
			height: 40px;
			min-width: 40px;
			min-height: 40px;
		}
	}
}

.comment-validation {
	background: $color-black-secondary;
	border: 1px solid $color-black-additional-third;
	padding: 10px 30px 10px 10px;
	box-sizing: border-box;
	border-radius: 12px;
	margin-bottom: 5px;
	position: relative;
}

.comment-validation .close {
	position: absolute;
	float: none;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
}

#comment-input {
	padding: 10px 40px 10px 12px;
	position: relative;
	min-height: 40px;
	z-index: 999;
}

#comment-input[placeholder]:empty:before {
	content: attr(placeholder);
	color: $color-gray-dark-additional-secondary;
}

#comment-input[placeholder]:empty:focus:before {
	content: '';
}

#comment-input:focus {
	outline-width: 0;
	border: 1px solid $color-black-additional-third;
	-webkit-tap-highlight-color: transparent;
}

button.send {
	right: 33px;
	bottom: 11px;
	transform: translateY(-50%);
	position: absolute;
	background: transparent url('@/assets/images/icons/send-disable.png') no-repeat center / cover;
	border: none;
	z-index: 9999;
	width: 20px;
	height: 20px;
	min-width: 20px;
	min-height: 20px;
	@media (min-width: $screen-desktop) {
		right: 38px;
		bottom: 23px;
	}
}

button.send.active {
	background-image: url('@/assets/images/icons/send-active.png');
	cursor: pointer;
	transition: $transition-filter;
	&:hover {
		filter: brightness(170%);
	}
}
</style>
