<template>
	<div
		@click="onSelect"
		class="history-item"
		:class="{
			active: filter?.currentGirl === id,
			opacity: waiting_subscription_result
		}"
	>
		<div class="image-container relative">
			<div class="image placeholder-bg">
				<img rel="preload" fetchpriority="high" :src="avatar" alt="" />
			</div>
			<div class="history-item-spinner" v-if="waiting_subscription_result">
				<loader-component size="24px" />
			</div>
		</div>
		<div class="history-item-name">{{ nickname }}</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useHomeStore } from '@/stores/home'
import LoaderComponent from '@/components/LoaderComponent.vue'

export default {
	name: 'FilterCard',
	props: {
		nickname: { type: String, default: '' },
		avatar: { type: String, default: '' },
		id: { type: Number, default: 0 },
		waiting_subscription_result: { type: Boolean, default: false }
	},
	components: {
		LoaderComponent
	},
	computed: {
		...mapState(useHomeStore, ['filter'])
	},
	methods: {
		...mapActions(useHomeStore, ['setFilter']),
		onSelect() {
			this.setFilter(this.id)
		}
	}
}
</script>

<style lang="scss" scoped>
.history-item-spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 18px;
	display: flex;
	//margin-left: 50%;
}

.history-item .image {
	display: flex;
	overflow: hidden;
	justify-content: center;
}
.image img {
	height: 100%;
	object-fit: contain;
}
.history-item {
	@media (min-width: $screen-desktop) {
		cursor: pointer;
		transition: opacity 0.3s ease-in-out;
		&:hover {
			opacity: 0.7;
		}
	}
}
</style>
