<template>
	<img class="upload-image-preview" v-show="src" :src="src" alt="" ref="previewImg" @load="onLoadEnd" />
</template>

<script>
import { mapActions } from 'pinia'
import { useModalStore } from '@/stores/modal'

export default {
	name: 'UploadImagePreview',
	props: {
		src: { type: String, default: '' }
	},
	methods: {
		...mapActions(useModalStore, ['setResizePopUp']),
		onLoadEnd() {
			const aspectRatio = this.$refs.previewImg.naturalWidth / this.$refs.previewImg.naturalHeight
			const naturalWidth =
				aspectRatio < 0.75
					? this.$refs.previewImg.naturalWidth + this.$refs.previewImg.naturalWidth * (1 - aspectRatio)
					: this.$refs.previewImg.naturalWidth
			this.setResizePopUp(
				{
					naturalWidth,
					naturalHeight: this.$refs.previewImg.naturalHeight
				},
				416
			)
		}
	}
}
</script>

<style lang="scss">
.upload-image-preview {
	width: 100%;
	height: auto;
	margin: auto;
	display: flex;
	border-radius: 12px;
	@media (min-width: $screen-desktop) {
		border-radius: 0;
	}
}
</style>
