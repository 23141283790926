<template>
	<dialog-component
		:class="['create-post', { 'create-post-nomedia': !hasAttachedMedia }]"
		:style-dialog="stylePopUp"
		@close="onClose"
		:click-outside-close="false"
	>
		<template #header-title>
			{{ isCreating ? 'Новая публикация' : 'Редактирование публикации' }}
		</template>
		<create-video-media :is-sending="isSending" v-show="hasAttachedVideo" />
		<create-photo-media
			ref="photo"
			:is-sending="isSending"
			v-model="post.photo"
			v-show="(hasAttachedPhoto && !hasAttachedVideo) || (isMobileDevice && heicProcessing)"
		/>
		<input-media-desktop v-if="!isMobileDevice && !hasAttachedMedia" />
		<div class="create-post-right">
			<form-component ref="form">
				<textarea-component
					name="description"
					v-model="post.description"
					:disabled="showModal"
					:class="['create-post-description', { 'create-post-description-disable': showModal }]"
					placeholder="Опиши свое настроение, расскажи небольшую историю или задай вопрос аудитории. Упоминай @никнейм, чтобы наградить кого-нибудь и не забудь #теги, чтобы быть на виду."
				/>
			</form-component>
			<post-fields-group
				@openAudienceModal="openAudienceModal"
				@openCalendar="openCalendar"
				@changeNotification="changeNotification"
				@removeDatePublic="removeDatePublic"
				:show-modal="showModal"
				:has-attached-media="hasAttachedMedia"
				:can-be-scheduled="post.can_be_scheduled"
				:date-public="datePublic"
				:post-audience="audienceList[post.audience]"
				:get-notification="getNotification"
				:scheduled-posts-enabled="scheduledPostsEnabled"
			/>
			<div class="btn-wrap">
				<button-component :color="colorButton" :loading="isSending" @click="onSubmitPost">
					{{ submitButtonText }}
				</button-component>
			</div>
		</div>
		<calendar-dialog
			v-show="calendarDialog"
			:scheduled-posts-enabled="scheduledPostsEnabled"
			:should-be-published-at="post.should_be_published_at"
			:is-editing="isEditing"
			ref="calendarDialog"
			@setPublicDate="setPublicDate"
			@close="closeCalendarDialog"
		/>
		<radio-list-dialog
			v-show="showAudienceModal"
			title="Приватность"
			:list-of-value="audienceList"
			:current-value="post.audience"
			@changeValue="changeAudience($event)"
			@close="closeAudienceModal"
		/>
		<validation-error-dialog
			v-show="showValidationDialog"
			:message="validationError.message"
			:description="validationError.description"
			@close="hideValidationDialog"
		/>
	</dialog-component>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useGirlProfileStore } from '@/stores/girlProfile'
import { useHomeStore } from '@/stores/home'
import { useVideoUploadStore } from '@/stores/videoUpload'
import { useCreatePostStore } from '@/stores/createPost'
import { useGirlSchedule } from '@/stores/girlSchedule'
import { useCommonStore } from '@/stores/common'
import { useModalStore } from '@/stores/modal'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import ValidationErrorDialog from '@/components/dialogs/ValidationErrorDialog.vue'
import RadioListDialog from '@/components/dialogs/RadioListDialog.vue'
import CalendarDialog from '@/components/dialogs/CalendarDialog.vue'
import TextareaComponent from '@/components/forms/TextareaComponent.vue'
import CreatePhotoMedia from '@/components/create-post/CreatePhotoMedia.vue'
import CreateVideoMedia from '@/components/create-post/CreateVideoMedia.vue'
import FormComponent from '@/components/forms/FormComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import InputMediaDesktop from '@/components/create-post/InputMediaDesktop.vue'
import PostFieldsGroup from '@/components/create-post/PostFieldsGroup.vue'

export default {
	name: 'CreatePostDialog',
	components: {
		FormComponent,
		CreateVideoMedia,
		CreatePhotoMedia,
		TextareaComponent,
		DialogComponent,
		ButtonComponent,
		ValidationErrorDialog,
		RadioListDialog,
		CalendarDialog,
		PostFieldsGroup,
		InputMediaDesktop
	},
	data() {
		return {
			showAudienceModal: false,
			getNotification: false,
			datePublic: '',
			calendarDialog: false,
			day: null,
			postsCount: null,
			formatDate: null
		}
	},
	computed: {
		...mapWritableState(useCreatePostStore, ['post', 'heicProcessing']),
		...mapState(useGirlSchedule, ['schedulesList', 'isLoading']),
		...mapState(useModalStore, [
			'showCropModal',
			'showCoverModal',
			'editVideoModal',
			'popUpSizeWidth',
			'popUpSizeHeight'
		]),
		...mapState(useCommonStore, ['isMobileDevice']),
		...mapState(useCreatePostStore, [
			'isEditing',
			'isSending',
			'isCreating',
			'fullBase64',
			'audienceList',
			'croppedBase64',
			'hasAudienceList',
			'validationError',
			'showValidationDialog'
		]),
		...mapState(useGirlProfileStore, ['id', 'nickname', 'avatar', 'verification_status']),
		hasAttachedVideo() {
			const { video, video_id, video_preview_id } = this.post
			return Boolean(video_id || video_preview_id || video)
		},
		hasAttachedPhoto() {
			return Boolean(this.fullBase64 || this.croppedBase64 || this.post.photo)
		},
		scheduledPostsEnabled() {
			return this.$config.scheduledPostsEnabled
		},
		colorButton() {
			if (this.showModal || !this.hasAttachedMedia || this.heicProcessing) {
				return 'disabled'
			}
			return 'primary'
		},
		submitButtonText() {
			if (this.datePublic) return 'Запланировать пост'
			return 'Опубликовать'
		},
		hasAttachedMedia() {
			return this.hasAttachedPhoto || this.hasAttachedVideo
		},
		showModal() {
			return this.showCropModal || this.showCoverModal || this.editVideoModal
		},
		stylePopUp() {
			if (document.documentElement.clientWidth > 980) {
				let newHeight = this.popUpSizeHeight
				if (this.popUpSizeHeight) {
					newHeight += this.showCropModal ? 200 : 0
				}
				return {
					width: `${this.popUpSizeWidth || 907}px`,
					height: `${newHeight}px`
				}
			}
			return {
				width: null,
				height: null
			}
		}
	},
	methods: {
		...mapActions(useVideoUploadStore, ['resetVideo']),
		...mapActions(useGirlSchedule, ['getScheduleByDay', 'getSchedulePosts', 'getSchedulesList']),
		...mapActions(useCreatePostStore, [
			'reset',
			'setMode',
			'getAudienceList',
			'editPostWithPhoto',
			'editPostWithVideo',
			'submitPostWithPhoto',
			'createPostWithVideo',
			'hideValidationDialog',
			'setDelayedPost'
		]),
		...mapActions(useCreatePostStore, ['closeCreatePostDialog']),
		...mapActions(useHomeStore, ['getPosts', 'resetPosts']),
		...mapActions(useModalStore, [
			'openVerificationNeedsDialog',
			'closeEditVideoModal',
			'closeCoverModal',
			'closeCropModal',
			'removePopUpSize'
		]),
		...mapActions(useGirlSchedule, ['resetSchedulePosts']),
		setPublicDate($event) {
			if ($event) {
				this.datePublic = `${this.$moment($event.day).format('DD.MM.YYYY')} в ${$event.currentTime}`
				const time = $event.currentTime.split(':')
				this.formatDate = this.$moment($event.day).add(time[0], 'hour').add(time[1], 'minute').toDate()
			}
			this.setDelayedPost({
				date: this.formatDate.toISOString(),
				notification: this.getNotification
			})
		},
		changeNotification() {
			this.getNotification = !this.getNotification
			this.setPublicDate()
		},
		openCalendar() {
			this.$refs.calendarDialog.closeTimePicker()
			this.calendarDialog = true
		},
		closeCalendarDialog() {
			this.calendarDialog = false
		},
		removeDatePublic() {
			this.getNotification = false
			this.datePublic = null
			this.$refs.calendarDialog.removeDatePublic()
			this.setDelayedPost(false, false)
		},
		onClose() {
			this.setMode('')
			this.reset()
			this.resetVideo()
			this.closeCropModal()
			this.closeCoverModal()
			this.closeEditVideoModal()
			this.removePopUpSize()
			this.$emit('close')
		},
		closeAudienceModal() {
			this.showAudienceModal = false
		},
		openAudienceModal() {
			this.showAudienceModal = true
		},
		onSubmitPost() {
			if (this.colorButton !== 'disabled') {
				if (this.hasAttachedVideo) {
					this.onSubmitPostWithVideo()
				} else {
					this.onSubmitPostWithPhoto()
				}
			}
		},
		async onSubmitPostWithPhoto() {
			try {
				if (this.isEditing) {
					await this.editPostWithPhoto()
				} else {
					await this.submitPostWithPhoto()
				}
				await this.onSuccessCreate()
			} catch (error) {
				this.$refs.form.setErrors(error.response.data.errors)
			}
		},
		async onSubmitPostWithVideo() {
			try {
				if (this.isEditing) {
					await this.editPostWithVideo()
				} else {
					await this.createPostWithVideo()
				}
				this.resetVideo()
				await this.onSuccessCreate()
			} catch (error) {
				this.$refs.form.setErrors(error.response.data.errors)
			}
		},
		async onSuccessCreate() {
			this.closeCreatePostDialog()
			this.setMode('')
			this.resetPosts()
			this.resetSchedulePosts()
			if (!this.datePublic) {
				if (this.$route.name === 'home') {
					await this.getPosts()
				} else {
					this.$router.push({ name: 'home' })
				}
			} else if (this.$route.name === 'author-schedule-detail') {
				await this.getSchedulesList()
				await this.getScheduleByDay(this.$route.params.day)
			} else if (this.$route.name === 'author-profile-schedule') {
				await this.getSchedulePosts()
			} else {
				this.$router.push(this.getAuthorProfile())
			}
			if (this.verification_status === 'needs') {
				this.openVerificationNeedsDialog()
			}
		},
		getAuthorProfile() {
			if (!this.nickname || this.verification_status === 'needs') {
				return { name: 'author-profile-schedule-id', params: { id: this.id } }
			}
			return { name: 'author-profile-schedule', params: { nickname: this.nickname, id: this.id } }
		},
		changeAudience(value) {
			this.post.audience = value
		}
	},
	mounted() {
		this.getAudienceList()
	}
}
</script>

<style lang="scss" scoped>
.create-post {
	:deep(.create-post-control) {
		font-size: 14px;
		display: flex;
		background: rgba($color-black, 0.5);
		border-radius: 100px;
		color: $color-white;
		border: none;
		vertical-align: center;
		cursor: pointer;

		&:hover {
			color: rgba($color-white, 0.8);
		}

		@media (max-width: 380px) and (max-height: 670px) {
			padding: 9px 5px;
			width: 130px;
		}
	}

	:deep(.create-post-nomedia .dialog) {
		@media (min-width: $screen-desktop) {
			width: 907px;
		}
	}

	:deep(.create-post-control span) {
		margin-left: 5px;
		font-size: 12px;
		font-weight: 700;
		@media (max-width: 380px) and (max-height: 670px) {
			font-size: 11px;
			margin-left: 2px;
		}
	}

	:deep(.form-field-wrap) {
		@media (min-width: $screen-desktop) {
			min-height: 135px;
		}
	}

	:deep(.dialog-header) {
		height: 40px;
		@media (min-width: $screen-desktop) {
			padding: 0;
			height: 0;
		}
	}

	:deep(.dialog-title) {
		position: absolute;
		margin: 0 auto;
		text-align: center;
		right: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		@media (min-width: $screen-desktop) {
			display: none;
		}
	}

	:deep(> .dialog) {
		height: 100%;
		display: flex;
		flex-direction: column;
		padding-bottom: 0;
		border-radius: 0;
		@media (min-width: $screen-desktop) {
			max-height: calc(100% - 80px);
			max-width: calc(100% - 80px);
			min-width: 907px;
			min-height: 440px;
			border-radius: 16px;
			padding-top: 0;
		}

		& > .dialog-body {
			overflow: auto;
			height: 100%;
			overscroll-behavior: none;
			@media (min-width: $screen-desktop) {
				display: flex;
				padding: 0 24px 0 0;
				justify-content: flex-end;
				border-radius: 20px;
				overflow: hidden;
			}
		}
	}

	:deep(.upload-image-preview) {
		height: 100%;
		object-fit: contain;
	}

	:deep(.inner-dialog) {
		.dialog {
			height: 100%;
			display: flex;
			flex-direction: column;
			padding-bottom: 0;
			border-radius: 0;
		}

		.dialog-body {
			overflow: auto;
			overscroll-behavior: none;
			height: 100%;
			display: flex;
			flex-direction: column;
		}

		.dialog-back {
			display: block;
		}
	}

	:deep(.create-post-image-controls) {
		position: absolute;
		bottom: 20px;
		left: 0;
		right: 0;
		z-index: 5;
		display: flex;
		justify-content: center;
		gap: 13px;
		margin: auto;
		width: 100%;

		& .create-post-control {
			padding: 9px;
			@media (min-width: $screen-desktop) {
				padding: 5px 9px;
			}
		}
	}

	:deep(.edit-video-controls) {
		@media (min-width: $screen-desktop) {
			position: absolute;
			bottom: 10px;
			width: 100%;
			left: 0;
			display: flex;
			justify-content: center;
			gap: 20px;
			& .create-post-image-controls {
				position: relative;
				bottom: 0;
				width: auto;
				margin: 0;
			}
		}
	}

	:deep(.mobile-wrap) {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	:deep(.desktop-wrap) {
		height: 100%;
		background-color: $color-black-additional-secondary;
		padding: 20px 40px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		@media (max-width: $screen-desktop) {
			margin-bottom: 20px;
			border-radius: 12px;
		}
	}
}

.create-post-description {
	:deep(.form-field) {
		margin-bottom: 9px;
		outline: none;
		min-height: 134px;
	}

	:deep(label) {
		display: none;
	}
}

.create-post-description-disable {
	cursor: default;
	opacity: 0.3;
}

.create-post-right {
	padding-bottom: 72px;
	@media (min-width: $screen-desktop) {
		width: 100%;
		max-width: 364px;
		right: 0;
		padding-top: 14px;
		position: relative;
	}

	.btn-wrap {
		background-color: $color-black;
		height: 72px;
		position: absolute;
		bottom: 0;
		width: 100%;
		left: 0;
	}

	& .btn {
		position: absolute;
		bottom: 10px;
		width: calc(100% - 40px);
		padding: 0 20px;
		margin: 0 auto;
		left: 0;
		right: 0;
		z-index: 10;
		@media (min-width: $screen-desktop) {
			width: 220px;
			bottom: 24px;
		}
	}
}
</style>
