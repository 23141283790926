import { createAuth } from '@websanova/vue-auth'
import driverAuth from '@/plugins/driverAuth'
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm'
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm'

export default (app) => {
	app.use(
		createAuth({
			rememberkey: 'auth_remember',
			tokenDefaultKey: 'auth_token_default',
			tokenImpersonateKey: 'auth_token_impersonate',
			stores: ['storage', 'cookie'],
			plugins: {
				http: app.axios,
				router: app.router
			},
			drivers: {
				auth: driverAuth,
				http: driverHttpAxios,
				router: driverRouterVueRouter
			},
			options: {
				rolesKey: 'role',
				notFoundRedirect: { name: 'home' },
				loginData: { enabled: false },
				fetchData: { enabled: true },
				refreshData: { enabled: false },
				authRedirect: () => window.location.replace(import.meta.env.VITE_AUTH_REDIRECT),
				forbiddenRedirect: null
			}
		})
	)
}
