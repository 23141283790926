<template>
	<div :class="['fixed-top', { invisible: !headerVisible, animate: headerAnimate, showMobile: showHeaderMobile }]">
		<div class="header flex row space container">
			<header-logo />
			<slot />
			<div :class="['title title-header', { 'hide-mobile': hideTitleMobile }]">
				<button v-if="showBackButton" type="button" @click="$router.back()" class="back">{{ title }}</button>
				<div v-else>{{ title }}</div>
			</div>

			<div class="header-right-group">
				<router-link
					v-if="isPrivatesEnabled"
					:to="notificationsRoute"
					class="header-notifications hide-desktop"
					data-navigation-link="header-notifications"
				>
					<div class="icon notifications" :class="[{ indicator: hasNotifications }]" />
				</router-link>
				<like-balance v-if="isConsumer" />
				<money-balance v-if="isGirl && showLkButton" />
				<div class="hide-mobile">
					<create-button
						v-if="$auth.check('girl') && !hideSidebar"
						:type="createButtonState"
						:header-button="true"
						:percentage="progress"
						v-create-post
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useCommonStore } from '@/stores/common'
import { useGirlProfileStore } from '@/stores/girlProfile'
import { useVideoUploadStore } from '@/stores/videoUpload'
import { createButtonTypes } from '@/constants'
import { useProfileStore } from '@/stores/profile'
import LikeBalance from '@/components/LikeBalance.vue'
import HeaderLogo from '@/components/HeaderLogo.vue'
import { useGirlSchedule } from '@/stores/girlSchedule'
import MoneyBalance from './MoneyBalance.vue'
import CreateButton from './CreateButton.vue'

export default {
	name: 'HeaderComponent',
	components: {
		LikeBalance,
		MoneyBalance,
		HeaderLogo,
		CreateButton
	},
	data() {
		return {
			headerVisible: true,
			headerAnimate: false,
			scrollPosition: 0
		}
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll)
	},
	methods: {
		...mapActions(useGirlSchedule, ['resetScheduleByDay']),
		handleScroll() {
			if (window.outerWidth < 980) {
				const currentScrollPosition = window.scrollY
				const setHeaderAttributes = (val) => {
					this.headerAnimate = val
					this.headerVisible = val
				}
				if (window.scrollY > 48 && this.scrollPosition !== 0) {
					setHeaderAttributes(false)
					if (currentScrollPosition < this.scrollPosition) {
						setHeaderAttributes(true)
					} else {
						setHeaderAttributes(false)
					}
				} else {
					setHeaderAttributes(true)
				}
				this.scrollPosition = window.scrollY
			}
		},
		openMedia() {
			this.resetScheduleByDay()
			this.$refs.mediaInput.openMedia()
		}
	},
	computed: {
		...mapState(useVideoUploadStore, ['progress']),
		...mapState(useProfileStore, {
			consumerNotifications: 'notifications',
			consumerPrivates: 'show_privates'
		}),
		...mapState(useGirlProfileStore, {
			showLkButton: 'show_lk_button',
			girlNotifications: 'notifications',
			girlPrivates: 'show_privates'
		}),
		...mapState(useCommonStore, ['isMobileScreen']),
		title() {
			return this.$route.meta.title
		},
		isConsumer() {
			return this.$auth.check('consumer')
		},
		isGirl() {
			return this.$auth.check('girl')
		},
		showHeaderMobile() {
			return ['balanceOnly', 'balanceWithMenu', 'home'].includes(this.$route.meta.layout)
		},
		isPrivatesEnabled() {
			return this.girlPrivates || this.consumerPrivates
		},
		hasNotifications() {
			return this.girlNotifications > 0 || this.consumerNotifications > 0
		},
		notificationsRoute() {
			return { name: this.isGirl ? 'author-notifications' : 'consumer-notifications' }
		},
		hideTitleMobile() {
			return this.$route.meta.layout === 'home'
		},
		showBackButton() {
			return ['balanceWithMenu', 'arrowBack'].includes(this.$route.meta.layout)
		},
		hideSidebar() {
			return this.$route.meta.hideSidebar
		},
		createButtonState() {
			if (this.progress === 0) {
				return createButtonTypes.default
			}
			if (this.progress === 100) {
				return createButtonTypes.ready
			}
			return createButtonTypes.loading
		}
	},
	beforeUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	}
}
</script>

<style lang="scss" scoped>
.header-notifications {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	@media (min-width: $screen-desktop) {
		display: none;
	}

	.icon {
		width: 28px;
		height: 28px;
		min-height: 28px;
		min-width: 28px;
		display: flex;
		flex-direction: row;
		justify-items: center;
		align-items: center;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
	}

	.notifications {
		background-image: url('@/assets/images/icons/notification-disable.png');

		&.indicator {
			position: relative;

			&::after {
				content: '';
				width: 6px;
				height: 6px;
				background: $color-blue-dark;
				border-radius: 50%;
				position: absolute;
				top: 0;
				right: 0;
			}
		}
	}
}

.fixed-top {
	position: fixed;
	top: -1px;
	left: 0;
	right: 0;
	width: 100%;
	max-width: $content-desktop;
	margin: 0 auto;
	background: $color-black;
	backdrop-filter: blur(12px);
	z-index: 200;
	@media (max-width: $screen-desktop) {
		max-width: $content-mobile;
		border-radius: 0 0 16px 16px;
		display: none;
	}

	&.showMobile {
		@media (max-width: $screen-desktop) {
			display: block;
		}
	}
}

.fixed-top.invisible {
	animation: hide 0.4s forwards;
}

.fixed-top.animate {
	transform: translateY(-150%);
	animation: show 0.4s forwards;
}

@keyframes show {
	0% {
		transform: translateY(-150%);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes hide {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-150%);
	}
}

.create-button-desktop {
	width: 45px;
	height: 45px;
	border-radius: 56px;
	background-color: $color-magenta-third;
	@media (max-width: $screen-desktop) {
		display: none;
	}
}

.header-right-group {
	display: flex;

	& .create-button-desktop {
		margin-left: 20px;
	}
}
</style>
