import { defineStore } from 'pinia'
import { getMoreData } from '@/stores/helper'
import { useTutorialStore } from '@/stores/tutorial'
import { useMessengerStore } from '@/stores/messenger'
import girlApi from '@/http/girl'
import girlProfileApi from '@/http/girl/profile/index'
import echo from '@/sockets/echo'

export const useGirlProfileStore = defineStore('girlProfile', {
	state: () => ({
		isProfileLoaded: false,
		about_yourself: '',
		balance: 0,
		id: null,
		nickname: '',
		nickname_approved: false,
		email: null,
		total_fans: 0,
		total_likes: 0,
		total_referrals: null,
		verification_status: null,
		avatar: '',
		avatar_id: null,
		cover: '',
		cover_id: null,
		email_notification_on: null,
		notifications: 0,
		notificationList: null,
		show_lk_button: null,
		is_active_referrer: false,
		isLoading: false,
		show_privates: false,
		latest_training_slider: null,
		can_delete_profile: null,
		waiting_delete_profile: null,
		messengerStore: useMessengerStore(),
		scheduled_posts: {
			minDelay: 0,
			range: 0
		}
	}),
	getters: {
		notificationsHasLoaded: (state) => state.notificationList !== null,
		isVerified: (state) => state.verification_status === 'approved',
		verificationInProcess: (state) => state.verification_status === 'in_process'
	},
	actions: {
		async getProfile() {
			const result = await girlProfileApi.getProfile()
			if (result) {
				Object.assign(this.$state, result)
				if (!echo.checkConnection()) {
					echo.connect(window.localStorage.auth_token_default)
				}
				if (this.show_privates && !this.isProfileLoaded) {
					echo.subscribe('girl', this.id)
					this.messengerStore.getUnreadThreadsCount()
				}
				if (result.verification_status === 'approved' && result.latest_training_slider === 'firstStep') {
					await useTutorialStore().startTutorial('secondStep')
				}
				this.isProfileLoaded = true
			}
		},
		async updateProfile(formData) {
			this.isLoading = true
			const result = await girlProfileApi.updateProfile(formData)
			if (result) {
				Object.assign(this.$state, formData)
			}
			this.isLoading = false
		},
		async loadAvatar(formData) {
			this.isLoading = true
			try {
				const result = await girlProfileApi.loadAvatar(formData)
				Object.assign(this.$state, result)
				return result
				// eslint-disable-next-line
			} catch (error) {
				throw error
			} finally {
				this.isLoading = false
			}
		},
		async loadCover(formData) {
			this.isLoading = true
			try {
				const result = await girlProfileApi.loadCover(formData)
				Object.assign(this.$state, result)
				return result
				// eslint-disable-next-line
			} catch (error) {
				throw error
			} finally {
				this.isLoading = false
			}
		},
		async getNotifications() {
			this.isLoading = true
			const result = await girlApi.getNotifications()
			if (result) {
				this.notifications = 0
				this.notificationList = result
			}
			this.isLoading = false
		},
		async getMoreNotifications() {
			await getMoreData(this.notificationList, girlApi.getMoreNotifications)
		},
		async enableEmailNotification() {
			const result = await girlApi.enableEmailNotification()
			if (result) {
				this.email_notification_on = true
			}
		},
		async disableEmailNotification() {
			const result = await girlApi.disableEmailNotification()
			if (result) {
				this.email_notification_on = false
			}
		}
	}
})
