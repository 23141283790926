<template>
	<div class="dialog-backdrop" @click.self="clickOutsideClose ? $emit('close') : null">
		<div :class="['dialog', { 'dialog-fullheight': fullheight }]" :style="styleDialog">
			<div class="dialog-header">
				<button type="button" @click="$emit('close')" class="back dialog-back" />
				<h3 :class="['dialog-title', { 'align-left': titleLeft }]">
					<slot name="header-title" />
				</h3>
				<button type="button" @click="$emit('close')" :class="['dialog-close-btn', crossClass]">
					<slot name="header-icon">
						<i class="i-cross" />
					</slot>
				</button>
			</div>
			<div @click.self="$emit('click')" :class="['dialog-body', { 'no-padding': nopadding }]">
				<slot />
			</div>
			<div :class="['dialog-footer', { 'no-padding': nopadding }]">
				<slot name="dialog-footer" />
			</div>
		</div>
	</div>
</template>

<script>
import ModalState from '@/helpers/modalState'

const modalState = new ModalState()

export default {
	name: 'DialogComponent',
	props: {
		fullheight: {
			type: Boolean,
			default: () => false
		},
		nopadding: {
			type: Boolean,
			default: () => false
		},
		titleLeft: {
			type: Boolean,
			default: () => false
		},
		crossClass: {
			type: String,
			default: () => ''
		},
		styleDialog: {
			type: Object,
			default: () => null
		},
		clickOutsideClose: {
			type: Boolean,
			default: true
		}
	},
	mounted() {
		this.$nextTick(() => {
			modalState.openModal(this.$el, window.scrollY)
		})
	},
	beforeUnmount() {
		modalState.closeModal(this.$el)
	}
}
</script>

<style style lang="scss" scoped>
.dialog-backdrop {
	position: fixed;
	left: 0;
	bottom: 0;
	background: rgba($color-black-total, 0.64);
	width: 100%;
	height: 100%;
	z-index: 999;
	display: flex;
	justify-content: center;

	@media (min-width: $screen-desktop) {
		align-items: center;
		overflow-y: auto;
	}
}

.dialog {
	position: fixed;
	bottom: 0;
	max-width: 500px;
	background: $color-black;
	border-radius: 16px 16px 0 0;
	width: 100%;
	padding: 10px 0;
	max-height: 100%;
	overflow: hidden;

	@media (min-width: $screen-desktop) {
		border-radius: 16px;
		bottom: auto;
		overflow: visible;
		padding: 15px 0;
	}
}

.dialog-fullheight {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.dialog-fullheight .dialog-body {
	flex: 1;
}

.dialog-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
}

.dialog-body,
.dialog-footer {
	padding: 10px 20px;
}

.no-padding {
	padding: 0;
}

.dialog-body:empty,
.dialog-footer:empty {
	display: none;
}

.dialog-title {
	font-size: 16px;
	line-height: 20px;
	font-weight: bold;
	max-width: 90%;
	color: $color-gray;
	@media (min-width: $screen-desktop) {
		margin: 0 auto;
	}

	@media (max-width: $screen-desktop) {
		&:empty + .dialog-close-btn {
			top: auto;
		}
	}
}

.dialog-close-btn {
	background: transparent;
	padding: 0;
	border: none;
	color: $color-gray-additional;
	font-size: 24px;
	cursor: pointer;
	display: flex;
	position: absolute;
	top: 21px;
	right: 16px;
	transition: $transition-color;

	&:hover {
		color: $color-gray-additional-hover;
	}
	&:active {
		color: $color-gray-additional-active;
	}

	@media (min-width: $screen-desktop) {
		right: -24px;
		top: -24px;
	}
}
.dialog-text {
	color: $color-gray;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 30px;
}

.dialog-text--white {
	color: $color-white;
}

.dialog-flex {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 30px;
}

.dialog-flex > * {
	margin-bottom: 0;
}

.dialog-flex *:first-child {
	margin-right: 12px;
}

.icon-ios-setting {
	width: 18px;
	height: 26px;
	margin-left: 2px;
	margin-right: 2px;
	display: inline-block;
	background: url('../../assets/images/icons/ios-setting.svg') center center no-repeat;
}

.dialog-back {
	display: none;
}

.hide-cross {
	display: none;
}

@media (min-width: $screen-desktop) {
	.dialog-dropdown {
		.dialog-backdrop {
			background-color: transparent;
		}
		.dialog {
			max-width: 300px;
			top: 135px;
			left: 0;
			right: calc(365px - $content-desktop);
			margin: 0 auto;
			box-shadow: 5px 4px 6px 0 rgba($color-black-total, 0.1);
			padding: 0 0 10px 0;
		}
		.dialog-header {
			padding: 0;
		}
		.dialog-close-btn {
			display: none;
		}
		.profile-menu {
			margin-bottom: 10px;
		}
		.profile-menu-item {
			padding: 10px 0;
			font-size: 14px;
		}
		.profile-btn-delete {
			margin-top: 5px;
		}
	}
}
</style>
