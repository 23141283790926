<template>
	<div class="tutorial">
		<div ref="swiper" class="create-post-preview swiper">
			<div class="swiper-wrapper">
				<div v-for="slide in slides" :key="slide" class="swiper-slide">
					<div :style="{ backgroundImage: `url(${slide})` }" class="tutorial-image" />
				</div>
			</div>
			<div class="swiper-controls">
				<div class="swiper-button-prev" />
				<div class="swiper-button-next" />
				<div class="swiper-pagination" />
			</div>
		</div>
	</div>
</template>

<script>
import { Swiper, Navigation, Pagination } from 'swiper'

export default {
	name: 'TutorialComponent',
	props: {
		slides: {
			type: Array,
			required: true
		}
	},
	mounted() {
		document.body.classList.add('disabled-scroll')
		const swiper = new Swiper(this.$refs.swiper, {
			modules: [Navigation, Pagination],
			pagination: {
				el: '.swiper-pagination'
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			}
		})
		swiper.on('reachEnd', () => {
			setTimeout(() => this.$emit('end'), 3000)
		})
	},
	beforeUnmount() {
		document.body.classList.remove('disabled-scroll')
	}
}
</script>

<style lang="scss">
.tutorial {
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);

	&-image {
		width: 100%;
		height: 100vh;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
}
.swiper {
	&-controls {
		position: absolute;
		bottom: 30px;
		left: 50%;
		transform: translateX(-50%);
		height: 20px;
		z-index: 99999;
	}
	&-button-next,
	&-button-prev {
		position: absolute;
		top: 0;
		height: 20px;
		width: 20px;
		&:before {
			content: '';
			position: absolute;
			border-top: 3px solid $color-white;
			border-right: 3px solid $color-white;
			width: 12px;
			height: 12px;
			top: 50%;
			left: 50%;
		}
	}
	&-button-prev {
		left: 0;
		&:before {
			transform: translate3d(-50%, -75%, 0px) rotate(225deg);
		}
	}
	&-button-next {
		right: 0;
		&:before {
			transform: translate3d(-50%, -75%, 0px) rotate(45deg);
		}
	}
	&-pagination {
		margin: 0 40px;
		display: flex;
		flex-flow: row nowrap;
		column-gap: 15px;
		z-index: 99999;
		align-items: center;
		height: 12px;
		&-bullet {
			width: 8px;
			height: 8px;
			border-radius: 20px;
			background-color: $color-semi-transparent;
			transition: all 0.3s linear;
			&-active {
				background-color: $color-white;
				width: 12px;
				height: 12px;
			}
		}
	}
}
</style>
