<template>
	<dialog-component class="radio-list-modal" cross-class="hide-desktop" @close="$emit('close')">
		<template #header-title> {{ title }}</template>
		<label class="create-post-radio" v-for="(label, value) in listOfValue" :key="value" @click="$emit('close')">
			<input
				hidden
				type="radio"
				:value="value"
				:checked="value === currentValue"
				name="post-visibility"
				@input="$emit('changeValue', $event.target.value)"
			/>
			<span>{{ label }}</span>
		</label>
	</dialog-component>
</template>

<script>
import DialogComponent from '@/components/dialogs/DialogComponent.vue'

export default {
	name: 'RadioListDialog',
	components: { DialogComponent },
	props: {
		listOfValue: {
			type: Object,
			default: () => {}
		},
		currentValue: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		}
	}
}
</script>

<style lang="scss" scoped>
.create-post-radio {
	padding: 4px 0;
	display: flex;
	align-items: center;
	& input:checked + span {
		color: $color-magenta-secondary;
	}
	&:not(:last-child) {
		margin-bottom: 12px;
	}
}

.radio-list-modal {
	@media (min-width: $screen-desktop) {
		&.dialog-backdrop {
			background: none;
			position: absolute;
			width: 100%;
		}
		:deep(.dialog) {
			max-width: 258px;
			height: 130px;
			padding: 30px 10px;
			background-color: $color-black-secondary;
			position: absolute;
			right: 40px;
			top: 168px;
			box-shadow:
				5px 20px 24.8px 11px rgba($color-black-total, 0.2),
				5px 4px 6.2px 0px rgba($color-black-total, 0.1);
		}
		:deep(.dialog-body) {
			display: flex;
			flex-direction: column;
			padding: 0;
		}
		:deep(.create-post-radio) {
			cursor: pointer;
			&:hover {
				color: rgba($color-white, 0.8);
			}
		}
		:deep(.create-post-radio:has(input:checked)) {
			&::after {
				content: '';
				width: 24px;
				height: 24px;
				position: absolute;
				background-image: url('@/assets/images/icons/check-audience.svg');
				right: 20px;
			}
		}
	}
}
</style>
